export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  breadcrumb: [

                    {
                      text: "Dashboard",
                      active: true
                    }
                  ],
                  title: 'Welcome!',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                   breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Account",
                        active: true
                      }
                  ],
                  title: 'Profile',
                  authRequired: true,
                },
            },
            {
                path: '/orders',
                name: 'order',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Orders",
                        active: true
                      }
                  ],
                  title: "Orders List",
                  authRequired: true,
                },
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'order-show',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Orders",
                      to:{ path:"/orders"}
                    },
                    {
                      text: "Show",
                      active: true
                    }
                ],
                title: "View Order",
                authRequired: true,
              },
            },
            {
              path: '/orders/:orderId(\\d+)/deliveries/:deliveryId(\\d+)',
              name: 'delivery-show',
              component: () => import('@views/orders/DeliveryShow.vue'),
              meta: {
                breadcrumb: [
                  {
                    text: "Orders",
                    to:{ path:"/orders"}
                  },
                  {
                    text: "Show",
                    active: true
                  }
                ],
                title: "View Delivery",
                authRequired: true,
              },
            },
            {
                path: '/users',
                name: 'users-list',
                component: () => import('@views/users/UserList.vue'),
                meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "User List",
                      active: true
                    }
                  ],
                  title: "Users List",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              redirect: to => {
                return { path: `/users/${to.params.userId}/edit` }
              },
              meta: {
                title: "Vew User",
                authRequired: true,
              },
            },
            {
                path: '/users/:userId(\\d+)/edit',
                name: 'user-edit',
                component: () => import('@views/users/edit/UserEdit.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "Users",
                        to: { path:"/users"}
                      },
                      {
                        text: "Edit User",
                        active: true
                      }
                  ],
                  title: "Vew User",
                  authRequired: true,
                },
            },
            {
              path: '/chats/:userId(\\d+)',
              name: 'user-chat',
              component: () => import('@views/chats/Chats.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "Users",
                      to: { path:"/users"}
                    },
                    {
                      text: "Chat user",
                      active: true
                    }
                ],
                title: "Chat user",
                authRequired: true,

              },
            },
            {
                path: '/reviews',
                name: 'reviews',
                component: () => import('@views/reviews/ReviewList.vue'),
                meta: {
                    breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "Review List",
                      active: true
                    }
                  ],
                  title: 'Reviews',
                  authRequired: true,
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@views/notifications/NotificationList.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Notifications List",
                        active: true
                      }
                  ],
                  title: "Notifications",
                  authRequired: true,
                }
            },
            {
              path: '/subscriptions',
              name: 'subscription',
              component: () => import('@views/subscriptions/SubscriptionList.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Subscriptions",
                      active: true
                    }
                ],
                pageTitle: "Subscriptions List",
                authRequired: true,
              },
            },
            {
              path: '/subscriptions/:subId(\\d+)',
              name: 'subscription-item',
              component: () => import('@views/subscriptions/SubscriptionItem.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/Dashboard"}
                    },
                    {
                      text: "Subscription overview",
                      active: true
                    }
                ],
                pageTitle: "Subscription overview",
                authRequired: true,
              },
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pages/Login.vue'),
                meta: {
                  title: "Login",
                  guestRequired: true,
                }
            },
            {
                path: '/error-404',
                name: 'error-404',
                component: () => import('@/views/pages/Error404.vue'),
                meta: {
                  title: "404 - Error"
                }
            },
            {
                path: '/error-500',
                name: 'error-500',
                component: () => import('@/views/pages/Error500.vue'),
                meta: {
                  title: "500 - Error"
                }
            },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/error-404'
    }
]
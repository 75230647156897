import axios from "@/plugins/axios.js"

const actions = {
  fetchProducts({ commit, state}, force = false) {
    if(!force && state.products.length) return
    return new Promise((resolve, reject) => {
      axios.get("/products")
        .then((response) => {
          if(response.data.success){
            commit('SET_PRODUCTS', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProduct({ commit }, productId) {
    return new Promise((resolve, reject) => {
      axios.get(`/products/${productId}/show`)
        .then((response) => {
          if(response.data.success){
               commit('SET_PRODUCT', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}

const getters = {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
}

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_PRODUCT(state, product) {
    state.product = product
  },
}

const state = {
  products: [],
  product:{}
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}


// axios
import axios from 'axios'
import router from "../router";
import env from '../env.js'

const axiosClient = axios.create({
  baseURL: `${env.domain}/api/staff/`,
  headers: {'X-Requested-With': 'XMLHttpRequest'}
});

axiosClient.interceptors.request.use((config) => {
  config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  config.headers.Authorization = `Bearer ${getoken()}`
  return config;
})

axiosClient.interceptors.response.use((response) => {
  alertMessage(response.data)
  return response;
}, (error) => {
  alertMessage(error.response.data)
  if (error.response.status === 401) {
    logout();
  }
  return error.response;
})

function getoken(){
  const localUser = localStorage.getItem(env.localUserKey);
  if(!localUser) return null;
  const storedData =  JSON.parse(unescape(atob(localUser)));
  return storedData.token;
}

function alertMessage(data){
  if(!data.message) return;
  if(Array.isArray(data.message)){
    data.message.forEach((msg) => {  data.success 
      ? toastr.success(msg, 'Success') 
      : toastr.error(msg, 'Something went worry', {timeOut: 3000})
    })
  }else{
    data.success 
      ? toastr.success(data.message, 'Success') 
      : toastr.error(data.message, 'Something went worry', {timeOut: 3000})
  }
}

function logout(){
  localStorage.removeItem(env.localUserKey)
  router.push({path: '/login'})
}

export default axiosClient
